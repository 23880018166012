.controller.auth-ctrl {
    width: 100%;
    height: 100%;
    background-color: #202c37;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;

    .form--image {
        max-width: 120px;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .form--wrapper {
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
        background-color: #FFF;
        border-radius: 1px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        padding: 12px;
        h2 {
            text-align: center;
        }
    }
}